import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logout, selectAccessToken } from './redux/authSlice';
const backendUrl = process.env.REACT_APP_BACKEND_URL;


const Header = () => {
  const dispatch = useDispatch();
  const accessToken = useSelector(selectAccessToken); // Select access token from Redux store
  const location = useLocation();
  const navigate = useNavigate();

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  useEffect(() => {
    setIsAuthenticated(!!accessToken);
  }, [accessToken]);

  const handleSignOut = async () => {
    try {
      const response = await fetch(`${backendUrl}/auth/logout`, {
        method: 'POST',
        credentials: 'include', // Ensure cookies are sent
      });

      if (response.ok) {
        dispatch(logout());
        navigate('/login');
      } else {
        console.error('Failed to logout');
      }
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  // Hide the header if on the login or 404 page
  if (location.pathname === '/login' || location.pathname === '/404'|| location.pathname === '/signup') {
    return null;
  }

  return (
    <header className="bg-white shadow-md">
      <div className="container mx-auto flex justify-between items-center py-4 px-6">
        {/* Hamburger Menu (left, for mobile) */}
        <div className="md:hidden">
          <button
            onClick={toggleMobileMenu}
            className="text-gray-600 hover:text-black focus:outline-none"
            aria-label="Open Menu"
          >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-6 w-6">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          </button>
        </div>

        {/* Logo (left) */}
        <div className="flex items-center">
          {/* <img src="/logo.svg" alt="Logo" className="h-8 mr-2" /> */}
          <span className="text-xl font-semibold text-gray-800">Aureli</span>
        </div>

        {/* Desktop Navigation (centered between logo and account sign-in) */}
        <nav className="hidden md:flex flex-grow justify-center space-x-8">
          <a href="/" className="text-gray-600 hover:text-black transition duration-200">Home</a>
          <a href="/about" className="text-gray-600 hover:text-black transition duration-200">About</a>
          <a href="/services" className="text-gray-600 hover:text-black transition duration-200">Services</a>
          <a href="/contact" className="text-gray-600 hover:text-black transition duration-200">Contact</a>
        </nav>

        {/* Sign In/Sign Out Button (far right) */}
        <div>
          {isAuthenticated ? (
            <button
              onClick={handleSignOut}
              className="text-sm text-gray-600 hover:text-black focus:outline-none"
            >
              Sign Out
            </button>
          ) : (
            <button
              onClick={() => navigate('/login')}
              className="text-sm text-gray-600 hover:text-black focus:outline-none"
            >
              Sign In
            </button>
          )}
        </div>
      </div>

      {/* Mobile Menu (shows when hamburger is clicked) */}
      {isMobileMenuOpen && (
        <div className="md:hidden bg-white shadow-md py-2 px-6">
          <a href="/" className="block text-gray-600 py-2">Home</a>
          <a href="/about" className="block text-gray-600 py-2">About</a>
          <a href="/services" className="block text-gray-600 py-2">Services</a>
          <a href="/contact" className="block text-gray-600 py-2">Contact</a>
        </div>
      )}
    </header>
  );
};

export default Header;
