import React from 'react';

const LoadingOverlay = ({ isLoading, message }) => {
  if (!isLoading) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="flex flex-col items-center">
        <div className="w-16 h-16 border-4 border-t-transparent border-white rounded-full animate-spin"></div>
        {message && <p className="text-white text-lg mt-4">{message}</p>}
      </div>
    </div>
  );
};

export default LoadingOverlay;
