import React from 'react';
import { useNavigate } from 'react-router-dom';

const IndexPage = () => {
  const navigate = useNavigate();

  return (
    <div className="bg-gradient-to-r from-blue-50 via-purple-100 to-green-200 min-h-screen flex items-center justify-center px-4 overflow-hidden relative">
      {/* Background Streaks */}
      <div className="absolute inset-0 bg-gradient-to-r from-transparent via-blue-200 to-transparent opacity-30 pointer-events-none z-10"></div>

      <div className="bg-white p-6 md:p-8 rounded-lg shadow-xl w-full max-w-2xl z-20">
        <h1 className="text-4xl font-bold text-gray-800 mb-4 text-center">Welcome to Aureli</h1>
        <p className="text-gray-600 mb-6 text-center">
          Monitor your investments, track your portfolio, and make informed financial decisions. Your gateway to smarter investing starts here.
        </p>

        <div className="flex justify-center space-x-4">
          <button
            onClick={() => navigate('/login')}
            className="bg-black text-white font-semibold py-2 px-6 rounded-md hover:bg-gray-800 focus:ring-2 focus:ring-gray-600 focus:ring-offset-2 focus:outline-none transition-all duration-300"
          >
            Login
          </button>

          <button
            onClick={() => navigate('/signup')}
            className="bg-gray-300 text-black font-semibold py-2 px-6 rounded-md hover:bg-gray-400 focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 focus:outline-none transition-all duration-300"
          >
            Sign Up
          </button>
        </div>

        <div className="mt-8 grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="bg-gradient-to-r from-blue-50 to-purple-100 p-4 rounded-lg shadow">
            <h3 className="text-lg font-semibold text-gray-800">View Investments</h3>
            <p className="text-sm text-gray-600 mt-2">
              Access detailed insights into your investment portfolio and track performance.
            </p>
          </div>
          <div className="bg-gradient-to-r from-purple-100 to-green-200 p-4 rounded-lg shadow">
            <h3 className="text-lg font-semibold text-gray-800">Portfolio Overview</h3>
            <p className="text-sm text-gray-600 mt-2">
              Get a comprehensive view of your assets and allocations in real-time.
            </p>
          </div>
          <div className="bg-gradient-to-r from-green-200 to-blue-50 p-4 rounded-lg shadow">
            <h3 className="text-lg font-semibold text-gray-800">Market Trends</h3>
            <p className="text-sm text-gray-600 mt-2">
              Stay updated on the latest market movements and financial news.
            </p>
          </div>
          <div className="bg-gradient-to-r from-blue-200 to-purple-100 p-4 rounded-lg shadow">
            <h3 className="text-lg font-semibold text-gray-800">Secure Access</h3>
            <p className="text-sm text-gray-600 mt-2">
              Your portfolio data is protected with industry-leading security measures.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndexPage;
