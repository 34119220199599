import React, { useState, useEffect } from 'react';
//import { useNotification } from '../utils/NotificationContext';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import { useDispatch, useSelector } from 'react-redux';
import { setAccessToken,selectAccessToken, setUser, setLoading, setError } from '../components/redux/authSlice';
const backendUrl = process.env.REACT_APP_BACKEND_URL;
console.log("backendUrl",backendUrl);
function LoginPage() {
  const dispatch = useDispatch();
  const { isLoading, error } = useSelector((state) => state.auth);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate(); // Hook to navigate programmatically
  const accessToken = useSelector(selectAccessToken); // Select access token from Redux store


  // Check if the user is already authenticated (token exists in sessionStorage)
  useEffect(() => {
    if (accessToken) {
      // If token exists, redirect to the dashboard or another page
      navigate('/dashboard'); // Replace with your desired route
      console.log('New accessToken value:', accessToken);

    }


    // Prevent body overflow on initial load
    const originalOverflow = document.body.style.overflow;
    document.body.style.overflow = 'hidden'; // Prevent scrolling initially

    // Restore original overflow behavior after the page loads
    return () => {
      document.body.style.overflow = originalOverflow;
    };
  }, [accessToken,navigate]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(setLoading(true)); // Start loading

    try {
      const response = await fetch(`${backendUrl}/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include', // Required for cookies
        body: JSON.stringify({ email, password }) // Send email and password
      });

      const responseBody = await response.json();

      if (!response.ok) {
        dispatch(setError(responseBody.error || 'Login failed'));
        dispatch(setLoading(false));
        return;
      }
      dispatch(setAccessToken(responseBody.accessToken));
      dispatch(setUser(responseBody.user)); // Optional: Store user info
      dispatch(setLoading(false));
      navigate('/dashboard');
      console.log(responseBody);

    } catch (error) {
      dispatch(setError('An error occurred. Please try again.'));
      dispatch(setLoading(false));
        }
  };

  return (
    <div className="bg-gradient-to-r from-blue-50 via-purple-100 to-green-200 min-h-screen flex items-center justify-center px-4 overflow-hidden relative">
      {/* Background Streaks */}
      <div className="absolute inset-0 bg-gradient-to-r from-transparent via-blue-200 to-transparent opacity-30 pointer-events-none z-10"></div>

      <div className="bg-white p-6 md:p-8 rounded-lg shadow-xl w-full max-w-md z-20">
        <h2 className="text-3xl font-semibold text-gray-800 mb-6 text-center">Sign In</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700 mb-2"
            >
              Email Address
            </label>
            <input
              type="email"
              id="email"
              className="w-full px-3 py-2 border border-gray-300 rounded-md text-gray-900 focus:ring-gray-500 focus:border-gray-500"
              value={email}
              autoComplete="email"
              onChange={(e) => setEmail(e.target.value)}
              required
              aria-label="Email address"
              autoFocus
            />
          </div>
          <div className="mb-6">
            <label
              htmlFor="password"
              className="block text-sm font-medium text-gray-700 mb-2"
            >
              Password
            </label>
            <input
              type="password"
              id="password"
              autoComplete="current-password"
              className="w-full px-3 py-2 border border-gray-300 rounded-md text-gray-900 focus:ring-gray-500 focus:border-gray-500"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              aria-label="Password"
            />
          </div>
          {error && (
            <p className="text-sm mb-4 text-red-500 text-center flex items-center justify-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-5 h-5 mr-2 text-red-600"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <circle cx="12" cy="12" r="11" stroke="currentColor" strokeWidth="2" fill="none" />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M12 9v6m0 4h.01"
                />
              </svg>
              {error}
            </p>
          )}
          <button
            type="submit"
            className="w-full bg-black text-white font-semibold py-2 px-4 rounded-md hover:bg-gray-800 focus:ring-2 focus:ring-gray-600 focus:ring-offset-2 focus:outline-none disabled:opacity-50 transition-all duration-300"
            disabled={isLoading}
            onFocus={(e) => e.target.classList.add('ring-2', 'ring-blue-400')}
            onBlur={(e) => e.target.classList.remove('ring-2', 'ring-blue-400')}
          >
            {isLoading ? (
              <div className="spinner mx-auto border-t-4 border-b-4 border-gray-900 border-solid w-6 h-6 rounded-full animate-spin"></div> // Spinner
            ) : (
              'Login'
            )}
          </button>
        </form>
        <p className="mt-4 text-sm text-gray-600 text-center">
          Don't have an account?{' '}
          <a href="/signup" className="text-gray-900 underline hover:text-gray-700">
            Sign Up
          </a>
        </p>
      </div>
    </div>
  );
}

export default LoginPage;
