import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const backendUrl = process.env.REACT_APP_BACKEND_URL; // Ensure correct environment variable

const initialState = {
  accessToken: null,
  user: null, // Optional: Store user data
  isLoading: false,
  error: null,
};

// Async thunk for refreshing the token
export const refreshTokenAsync = createAsyncThunk(
  'auth/refreshToken',
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetch(`${backendUrl}/auth/refreshToken`, {
        method: 'POST',
        credentials: 'include', // Include HttpOnly cookie
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Failed to refresh token');
      }

      const data = await response.json();
      return data.accessToken; // Return the new access token
    } catch (error) {
      return rejectWithValue(error.message); // Return error message to handle in the reducer
    }
  }
);

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAccessToken: (state, action) => {
      state.accessToken = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    logout: (state) => {
      state.accessToken = null;
      state.user = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(refreshTokenAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null; // Clear any previous error
      })
      .addCase(refreshTokenAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.accessToken = action.payload; // Update state with the new access token
      })
      .addCase(refreshTokenAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || 'Failed to refresh token'; // Store the error message
      });
  },
});

export const { setAccessToken, setUser, setLoading, setError, logout } = authSlice.actions;

// Selectors
export const selectAccessToken = (state) => state.auth.accessToken;
export const selectAuthLoading = (state) => state.auth.isLoading;
export const selectAuthError = (state) => state.auth.error;
export const selectUser = (state) => state.auth.user;

export default authSlice.reducer;
