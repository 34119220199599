import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import store, { persistor } from './components/redux/store'; // Import Redux store and persistor
import LoginPage from './pages/LoginPage';
import { NotificationProvider } from './utils/NotificationContext';
import Header from './components/header';
import ProtectedRoute from './components/ProtectedRoute';
import NotFoundPage from './components/404NotFound';
import ForgotPasswordPage from './pages/ForgotPassword';
import ResetPasswordPage from './pages/ResetPassword';
import LoadingOverlay from './components/LoadingOverlay';
import Dashboard from './pages/Dashboard';
import IndexPage from './pages/IndexPage';
import SignupPage from './pages/SignUpPage';
import EmailVerifyPage from './pages/verify';
import ResendVerificationPage from './pages/resendEmailVerify';

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const handlePageLoad = () => setLoading(false);

    if (document.readyState === 'complete') {
      handlePageLoad();
    } else {
      window.addEventListener('load', handlePageLoad);
      return () => window.removeEventListener('load', handlePageLoad);
    }
  }, []);

  if (loading) {
    return <LoadingOverlay isLoading={loading} message="Loading, please wait..." />;
  }

  return (
    <Provider store={store}>
      <PersistGate loading={<LoadingOverlay isLoading={true} message="Initializing app..." />} persistor={persistor}>
        <NotificationProvider>
          <Router>
            <div className="App">
              <Header />
              <Routes>
                {/* Public Routes */}
                <Route path="/" element={<IndexPage />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/signup" element={<SignupPage />} />
                <Route path="/verify-email/:code" element={<EmailVerifyPage />} />
                <Route path="/resend-email" element={<ResendVerificationPage />} />
                <Route path="/forgot-password" element={<ForgotPasswordPage />} />
                <Route path="/reset-password/:token" element={<ResetPasswordPage />} />

                {/* Protected Routes */}
                <Route path="/dashboard" element={<ProtectedRoute element={<Dashboard />} />} />

                {/* 404 Not Found */}
                <Route path="*" element={<Navigate to="/404" />} />
                <Route path="/404" element={<NotFoundPage />} />
              </Routes>
            </div>
          </Router>
        </NotificationProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
