import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
const backendUrl = process.env.REACT_APP_BACKEND_URL;

const SignupPage = () => {
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  var [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  email=email.toLowerCase();
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setErrorMessage('');

    if (password !== confirmPassword) {
      setErrorMessage('Passwords do not match.');
      setIsLoading(false);
      return;
    }

    try {
      const response = await fetch(`${backendUrl}/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ firstName, lastName, email, password }),
      });

      const responseBody = await response.json();

      if (!response.ok) {
        setErrorMessage(responseBody.error || 'Signup failed.');
        setIsLoading(false);
        return;
      }

      navigate('/login');
    } catch (error) {
      console.error('Error:', error);
      setErrorMessage('An error occurred. Please try again.');
      setIsLoading(false);
    }
  };

  return (
    <div className="bg-gradient-to-r from-blue-50 via-purple-100 to-green-200 min-h-screen flex items-center justify-center px-4 overflow-hidden relative">
      {/* Background Streaks */}
      <div className="absolute inset-0 bg-gradient-to-r from-transparent via-blue-200 to-transparent opacity-30 pointer-events-none z-10"></div>

      <div className="bg-white p-6 md:p-8 rounded-lg shadow-xl w-full max-w-md z-20">
        <h2 className="text-3xl font-semibold text-gray-800 mb-6 text-center">Sign Up</h2>
        <form onSubmit={handleSubmit}>
          <div className="flex gap-4 mb-4">
            <div className="w-1/2">
              <label
                htmlFor="first-name"
                className="block text-sm font-medium text-gray-700 mb-2"
              >
                First Name
              </label>
              <input
                type="text"
                id="first-name"
                className="w-full px-3 py-2 border border-gray-300 rounded-md text-gray-900 focus:ring-gray-500 focus:border-gray-500"
                value={firstName}
                autoComplete="given-name"
                onChange={(e) => setFirstName(e.target.value)}
                required
                aria-label="First Name"
              />
            </div>
            <div className="w-1/2">
              <label
                htmlFor="last-name"
                className="block text-sm font-medium text-gray-700 mb-2"
              >
                Last Name
              </label>
              <input
                type="text"
                id="last-name"
                autoComplete="family-name"
                className="w-full px-3 py-2 border border-gray-300 rounded-md text-gray-900 focus:ring-gray-500 focus:border-gray-500"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
                aria-label="Last Name"
              />
            </div>
          </div>
          <div className="mb-4">
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700 mb-2"
            >
              Email Address
            </label>
            <input
              type="email"
              id="email"
              className="w-full px-3 py-2 border border-gray-300 rounded-md text-gray-900 focus:ring-gray-500 focus:border-gray-500"
              value={email}
              autoComplete="email"
              onChange={(e) => setEmail(e.target.value)}
              required
              aria-label="Email address"
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="password"
              className="block text-sm font-medium text-gray-700 mb-2"
            >
              Password
            </label>
            <input
              type="password"
              id="password"
              autoComplete="new-password"
              className="w-full px-3 py-2 border border-gray-300 rounded-md text-gray-900 focus:ring-gray-500 focus:border-gray-500"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              aria-label="Password"
            />
          </div>
          <div className="mb-6">
            <label
              htmlFor="confirm-password"
              className="block text-sm font-medium text-gray-700 mb-2"
            >
              Confirm Password
            </label>
            <input
              type="password"
              id="confirm-password"
              autoComplete="new-password"
              className="w-full px-3 py-2 border border-gray-300 rounded-md text-gray-900 focus:ring-gray-500 focus:border-gray-500"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
              aria-label="Confirm Password"
            />
          </div>
          {errorMessage && (
            <p className="text-sm mb-4 text-red-500 text-center">
              {errorMessage}
            </p>
          )}
          <button
            type="submit"
            className="w-full bg-black text-white font-semibold py-2 px-4 rounded-md hover:bg-gray-800 focus:ring-2 focus:ring-gray-600 focus:ring-offset-2 focus:outline-none disabled:opacity-50 transition-all duration-300"
            disabled={isLoading}
          >
            {isLoading ? 'Creating Account...' : 'Sign Up'}
          </button>
        </form>
        <p className="mt-4 text-sm text-gray-600 text-center">
          Already have an account?{' '}
          <a href="/login" className="text-gray-900 underline hover:text-gray-700">
            Login
          </a>
        </p>
      </div>
    </div>
  );
};

export default SignupPage;
