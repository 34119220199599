import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {jwtDecode} from 'jwt-decode';
import { refreshTokenAsync, selectAccessToken, selectAuthLoading } from './redux/authSlice';
import LoadingOverlay from './LoadingOverlay';

function ProtectedRoute({ element }) {
  const dispatch = useDispatch();
  const accessToken = useSelector(selectAccessToken); // Access token from Redux store
  const authLoading = useSelector(selectAuthLoading); // Loading state from Redux store
  const [initialLoading, setInitialLoading] = useState(true); // Local loading during the first mount
  console.log(authLoading)
  /**
   * Helper function: Determine if a JWT token is expired
   * by decoding it and comparing the current time with the `exp` claim.
   */
  const isTokenExpired = (token) => {
    try {
      const { exp } = jwtDecode(token);
      console.log(jwtDecode(token));
      // `exp` is in seconds; convert to ms by multiplying by 1000
      // Compare with current time (in ms)
      return Date.now() >= exp * 1000;
    } catch (error) {
      console.error('Error decoding token:', error);
      // If there's an error decoding, assume token is invalid/expired
      return true;
    }
  };

  useEffect(() => {
    /**
     * If there's no access token or the access token is expired,
     * try to refresh it.
     */
    const checkAndRefreshToken = async () => {
      if (!accessToken || isTokenExpired(accessToken)) {
        try {
          console.log('Refreshing token...');
          await dispatch(refreshTokenAsync());
        } catch (error) {
          console.error('Failed to refresh token:', error);
        } finally {
          setInitialLoading(false);
        }
      } else {
        console.log('Access token is valid');
        setInitialLoading(false);
      }
    };

    checkAndRefreshToken();
  }, [accessToken, dispatch]);

  // Show a loading overlay while:
  // 1) We haven't finished the initial token check, or
  // 2) The global authLoading indicates a refresh attempt is in progress.
  if (initialLoading || authLoading) {
    console.log('initialLoading:', initialLoading);
    return <LoadingOverlay isLoading={true} message="Authenticating, please wait..." />;
  }

  // If, after the check, we have no valid token, redirect to the login page.
  if (!accessToken || isTokenExpired(accessToken)) {
    return <Navigate to="/login" replace />;
  }

  // Otherwise, render the protected component.
  return element;
}

export default ProtectedRoute;
