import React from 'react';

function Dashboard() {
  return (
    <div className="bg-gray-100 min-h-screen flex flex-col">
      {/* Header */}
      <header className="bg-black text-white py-4 px-6 shadow-md">
        <div className="flex justify-between items-center">
          <h1 className="text-2xl font-bold">Dashboard</h1>
          <button className="bg-gray-700 hover:bg-gray-600 px-4 py-2 rounded-md focus:ring-2 focus:ring-gray-500 focus:ring-offset-2">Logout</button>
        </div>
      </header>

      {/* Sidebar and Content Wrapper */}
      <div className="flex flex-1">
        {/* Sidebar */}
        <aside className="bg-white shadow-md w-64 p-6">
          <nav>
            <ul className="space-y-4">
              <li>
                <a
                  href="#overview"
                  className="block py-2 px-4 rounded-md text-gray-700 hover:bg-gray-200 focus:ring-2 focus:ring-gray-500"
                >
                  Overview
                </a>
              </li>
              <li>
                <a
                  href="#analytics"
                  className="block py-2 px-4 rounded-md text-gray-700 hover:bg-gray-200 focus:ring-2 focus:ring-gray-500"
                >
                  Analytics
                </a>
              </li>
              <li>
                <a
                  href="#settings"
                  className="block py-2 px-4 rounded-md text-gray-700 hover:bg-gray-200 focus:ring-2 focus:ring-gray-500"
                >
                  Settings
                </a>
              </li>
            </ul>
          </nav>
        </aside>

        {/* Main Content */}
        <main className="flex-1 p-8">
          <div className="bg-white rounded-lg shadow p-6 mb-8">
            <h2 className="text-xl font-bold mb-4">Welcome Back!</h2>
            <p className="text-gray-600">
              This is your dashboard where you can manage your account, view analytics, and customize settings.
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6">
            {/* Cards */}
            <div className="bg-white rounded-lg shadow p-6">
              <h3 className="text-lg font-semibold mb-2">Analytics</h3>
              <p className="text-gray-600">View and track your performance metrics.</p>
            </div>

            <div className="bg-white rounded-lg shadow p-6">
              <h3 className="text-lg font-semibold mb-2">Tasks</h3>
              <p className="text-gray-600">Manage your daily to-dos and tasks.</p>
            </div>

            <div className="bg-white rounded-lg shadow p-6">
              <h3 className="text-lg font-semibold mb-2">Notifications</h3>
              <p className="text-gray-600">Check your recent notifications and updates.</p>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default Dashboard;
