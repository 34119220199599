import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const EmailVerifyPage = () => {
  const { code } = useParams();
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (!code) {
      setErrorMessage('Invalid or missing verification code.');
      setIsLoading(false);
      return;
    }

    const verifyEmail = async () => {
      try {
        const response = await fetch(`${backendUrl}/verifyEmail`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ code }),
        });

        const responseBody = await response.json();

        if (!response.ok) {
          setErrorMessage(responseBody.error || 'Verification failed.');
          setIsLoading(false);
          return;
        }
        await new Promise(resolve => setTimeout(resolve, 2000));
        navigate('/login');
      } catch (error) {
        console.error('Error:', error);
        setErrorMessage('An error occurred. Please try again.');
        setIsLoading(false);
      }
    };

    verifyEmail();
  }, [code, navigate]);

  return (
    <div className="bg-gradient-to-r from-blue-50 via-purple-100 to-green-200 min-h-screen flex items-center justify-center px-4 overflow-hidden relative">
      {/* Background Streaks */}
      <div className="absolute inset-0 bg-gradient-to-r from-transparent via-blue-200 to-transparent opacity-30 pointer-events-none z-10"></div>

      <div className="bg-white p-6 md:p-8 rounded-lg shadow-xl w-full max-w-md z-20">
        <h2 className="text-3xl font-semibold text-gray-800 mb-6 text-center">Email Verification</h2>
        {isLoading ? (
          <p className="text-sm text-gray-600 text-center">Verifying, please wait...</p>
        ) : errorMessage ? (
          <p className="text-sm text-red-500 text-center">{errorMessage}</p>
        ) : null}
      </div>
    </div>
  );
};

export default EmailVerifyPage;
